@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

/* Opcional: Aplica Montserrat a un selector global */
body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

@-moz-keyframes spin {
  from {
      -moz-transform: rotate(0deg);
  }

  to {
      -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
  }

  to {
      -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 8px; 
}

::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 6px; 
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}

* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

*::-ms-scrollbar {
  width: 12px;
}

*::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

*::-ms-scrollbar-track {
  background-color: #f1f1f1;
}
